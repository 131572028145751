
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';
const INSTAGRAM_URL = 'https://www.instagram.com/schubergphilis/';

export default {
  inheritAttrs: false,
  props: {
    name: oneOfType([string(), nullable()]),
    username: oneOfType([string(), nullable()]),
    profilePicture: arrayOf(shape(imageShape).loose),
    visuals: arrayOf(shape(imageShape).loose),
  },
  data() {
    return { INSTAGRAM_URL };
  },
};
