
export default {
  inheritAttrs: false,
  data() {
    return { mounted: false, IntersectionObserver: null };
  },
  computed: {
    trackWidth() {
      if (!this.mounted) return 0;

      return this.$refs.baseTrack.clientWidth;
    },
    trackCount() {
      if (!this.mounted || !this.$refs.container || this.trackWidth <= 0)
        return 0;

      const min = (this.$refs.container.offsetWidth * 2) / this.trackWidth;

      return Math.max(1, Math.ceil(min) - 1);
    },
    trackSpeed() {
      // 50px per second.
      return this.trackWidth / 50;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  beforeDestroy() {
    if (this.IntersectionObserver) this.IntersectionObserver.disconnect();
  },
  methods: {
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '25px',
        threshold: 0,
      };

      this.IntersectionObserver = new IntersectionObserver((entries) => {
        const intersecting = !!this.$first(entries).isIntersecting;
        if (intersecting && !this.mounted) {
          this.mounted = true;
        }
      }, options);

      this.IntersectionObserver.observe(this.$refs.container);
    },
  },
};
