import { render, staticRenderFns } from "./Instagram.vue?vue&type=template&id=4bcb3db9&scoped=true"
import script from "./Instagram.vue?vue&type=script&lang=js"
export * from "./Instagram.vue?vue&type=script&lang=js"
import style0 from "./Instagram.vue?vue&type=style&index=0&id=4bcb3db9&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcb3db9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImage: require('/src/components/atoms/Image.vue').default,AtomsIcon: require('/src/components/atoms/Icon.vue').default,MoleculesVisual: require('/src/components/molecules/Visual.vue').default,AtomsMarquee: require('/src/components/atoms/Marquee.vue').default,AtomsButton: require('/src/components/atoms/Button.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,AtomsModuleWrapper: require('/src/components/atoms/ModuleWrapper.vue').default})
